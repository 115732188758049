<template>
  <div>
    <div v-if="bannelUrl">
      <img class="category-banner" :src="bannelUrl" />
    </div>
    <div v-if="dataList.length > 0">
      <div v-if="type == 1">
        <div
          v-for="(item, index) in dataList"
          :index="index"
          clickable
          :key="index"
          @click="click(item)"
          class="wzlb-list-item"
        >
          <span class="wzlb-list-item-view">{{ item.title }}</span>
        </div>
      </div>
      <div v-else class="zt-news-list">
        <van-cell
          is-link
          v-for="(item, index) in dataList"
          :index="index"
          :key="index"
          @click="click(item)"
          class="wzlb-list-item"
        >
          <template #title>
            {{ item.title }}
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import _ from "lodash";
export default {
  name: "article-new",
  data() {
    return {
      bannelUrl: "",
      dataList: [],
      articleList: [],
      categoryList: [],
      type: 1,
    };
  },
  methods: {
    getContentList() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http
        .get("/api/content/infoByParentId", postData)
        .then((res) => {
          this.categoryList = [];
          this.articleList = [];
          this.dataList = [];
          var realData = res.data;
          if (realData.category) {
            this.bannelUrl = realData.category.imgUrl;
          }
          if (realData.data && realData.data.length > 0) {
            this.categoryList = realData.data;
            for (var i = 0; i < realData.data.length; i++) {
              realData.data[i]["_type"] = "category";
              // realData.data[i]['publishDate'] = realData.data[i].addTime.split(' ')[0]
              this.dataList.push(realData.data[i]);
            }
          }
          if (realData.contents && realData.contents.length > 0) {
            this.articleList = realData.contents;
            for (var i = 0; i < realData.contents.length; i++) {
              realData.contents[i]["_type"] = "article";
              // realData.data[i]['publishDate'] = realData.data[i].addTime.split(' ')[0]
              this.dataList.push(realData.contents[i]);
              if (this.categoryList && this.categoryList.length == 0) {
                // 没有文章分类，只有文章列表，设置文章列表类别：1普通图文，2院内新闻，3：健康资讯
                this.type = realData.contents[i].type || 1;
              }
            }
          }
          if (!this.dataList || this.dataList.length == 0) {
            Toast({
              message: "没有找到相关信息",
            });
          }
          this.dataList = _.sortBy(this.dataList, function (o) {
            return o.sortId;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    click(item) {
      window.sessionStorage.setItem("currentArticle", JSON.stringify(item));
      this.$router.push({
        path: "/articleDetail",
      });
    },
  },
  mounted() {
    this.getContentList();
  },
};
</script>

<style scoped>
.wzlb-list-item {
  text-align: left;
}
.wzlb-list-item-view {
  font-size: 0.4rem;
}
/deep/.van-cell__title {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
</style>
